/*
Custom Css
*/
body {
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif;
    letter-spacing: 2px;
    font-size: 30px;
    text-align: justify;
}

.h-index {
    z-index: 1000;
}

.l-index {
    z-index: -1000;
}

.font-1 {
    font-family: "Bellefair", serif;
}

.font-2 {
    font-family: "Poppins", sans-serif;
}

.font-3 {
    font-family: "Montserrat", sans-serif;
}

.heading-text {
    font-family: "Bellefair", serif;
    font-size: 50px;
}

.title-text {
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 45px;
    padding-right: 15px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.custom-container {
    margin: 0px 300px;
}

.pt {
    padding-top: 50px;
}

.pt-sm {
    padding-top: 30px;
}

.pb {
    padding-bottom: 50px;
}

.ms {
    margin-left: 240px;
}

.row {
    --bs-gutter-x: 1.5rem;
}

.bg-color-1 {
    color: white;
    background-color: #000000;
}

.bg-color-2 {
    background-color: #494949;
}

/*Navbar Logo */
.logo_img {
    height: 60px;
    width: auto;
}

.email-svg {
    height: 40px;
}

.active {
    color: #0D6EFD !important;
}

/* Home page */
/* Main Header Text */
.header-text-home {
    width: 100%;
    height: 35%;
    top: 25%;
}

.header-container-home {
    height: 85vh;
    width: 100vw;
    position: relative;
    align-items: center;
    justify-content: center;
    background: url("../img/bg-30.jpg");
    background-position: 50% 0%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: calc(1520px);
}

.header-container-home::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #c0c0c0;
    opacity: 0.4;
}

.home-logo-img {
    height: 290px;
    width: auto;
    position: absolute;
    left: 25%;
}

.parallax-text {
    position: absolute;
    font-family: "Montserrat", sans-serif;
    font-family: 'Noto Naskh Arabic', serif;
    font-size: 5vw;
    font-weight: 700;
    left: 29%;
    top: 22.3%;
    color: rgb(18, 18, 18);
}

.parallax-text::after {
    content: attr(text);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    color: transparent;
    -webkit-text-stroke: 0.15vw rgb(18, 18, 18);
}

.header-text-home img {
    margin-top: 9.5%;
    width: 3.5%;
    z-index: -100;
    animation: diamond_anim 4s cubic-bezier(0.25, 1, 0.5, 1) infinite alternate;
}

@keyframes diamond_anim {
    0%,
    10% {
        transform: translate(100vw, 0) rotate(45deg);
    }

    90%,
    100% {
        transform: translate(-9vw, 0) rotate(-180deg);
    }
}

.header-text-home-1 {
    margin-top: 80px;
    margin-right: 140px;
}

.home_section_bg_1 {
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.home-section-2-img {
    height: 85vh;
    width: 100vw;
    opacity: 0.4;
}

.home-heading {
    font-size: 25px;
}

/* Button */
.cta {
    border: none;
    background: none;
}

.cta span {
    padding-bottom: 7px;
    letter-spacing: 4px;
    font-size: 20px;
    padding-right: 15px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}

.cta svg {
    margin-top: -5px;
    transform: translateX(-8px);
    transition: all 0.3s ease;
}

.cta:hover svg {
    transform: translateX(0);
}

.cta:active svg {
    transform: scale(0.9);
}

.hover-underline-animation {
    position: relative;
    color: black;
    padding-bottom: 20px;
}

.hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000000;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* review */
.card {
    box-sizing: border-box;
    width: 190px;
    height: auto;
    background: rgba(255, 255, 255, 0.58);
    border: 1px solid white;
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(6px);
    border-radius: 17px;
    cursor: pointer;
    transition: all 0.5s;
    display: flex;
    user-select: none;
    font-weight: bolder;
    text-align: start;
}

.card:hover {
    border: 1px solid black;
    transform: scale(1.05);
}

.card:active {
    transform: scale(0.95) rotateZ(1.7deg);
}

/* Home end */

/* Products */
.header-container-products {
    height: 85vh;
    width: 100vw;
    position: relative;
    align-items: center;
    justify-content: center;
    background: url("../img/bg-38.webp");
    background-position: 0px 240%;
    background-size: calc(1520px);
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.header-container-products::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #c0c0c0;
    opacity: 0.4;
    overflow-x: hidden
}

.header-products-text {
    top: 30%;
    left: 0%;
}

.header-education-text {
    top: 30%;
    left: 0%;
}

.header-about-text {
    top: 30%;
    left: 10%;
}


/* product gallary */
.product-section-2 {
    height: auto;
    width: 100vw;
    align-items: center;
    justify-content: center;
    /* background-color: #c0c0c0; */
}

.product-gallary {
    /* height: 100%; */
    width: 100%;
    /* margin: 30px 0; */
    padding-bottom: 50px;
    z-index: -1;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.container-box {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-flow: 100px 300px;
    grid-gap: 10px;
    grid-auto-flow: dense;
    height: 88.5vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px;
    /* background-color:#000000; */
    /* background-color:#494949; */
    /* border-radius: 20px; */
}

/* scrollbar  */
.container-box::-webkit-scrollbar {
    display: none;
}

.container-box {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
    /* border-radius: 10px; */
    box-shadow: .5px .5px 6px rgb(200, 196, 196);
}

.gallery-item .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border-radius: 10px; */
}

.gallery-item .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    /* cursor: pointer; */
    transition: .5s ease-in-out;
}

.gallery-item:hover .image img {
    transform: scale(1.5);
}

.w-1 {
    grid-column: span 1;
}

.w-2 {
    grid-column: span 2;
}

.w-3 {
    grid-column: span 3;
}

.w-4 {
    grid-column: span 4;
}

.w-5 {
    grid-column: span 5;
}

.w-6 {
    grid-column: span 6;
}

.h-1 {
    grid-row: span 1;
}

.h-2 {
    grid-row: span 2;
}

.h-3 {
    grid-row: span 3;
}

.h-4 {
    grid-row: span 4;
}

.h-5 {
    grid-row: span 5;
}

.h-6 {
    grid-row: span 6;
}

@media screen and (max-width: 769px) {
    .container-box {
        grid-template-columns: repeat(1, 1fr);
    }

    .w-1,
    .w-2,
    .w-3,
    .w-4,
    .w-5,
    .w-6 {
        grid-column: span 1;
    }
}

/* product gallary end */

.product-section-3 {
    height: auto;
    width: 100vw;
}

/* .product-section-3-img {
  align-self: center;
  height: 700px;
  width: auto;
} */
/* Product ends */

/* Educational */
.header-container-education-1 {
    height: 85vh;
    width: 100vw;
    position: relative;
    align-items: center;
    justify-content: center;
    background: url("../img/bg-48.jpeg");
    background-position: 0% 40%;
    background-size: calc(1620px);
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.header-container-education-1::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #c0c0c0;
    opacity: 0.4;
}


.diamond_4c_main_img {
    width: 300px;
    height: auto;
}

.diamond_4c_img {
    width: 400px;
    height: auto;
}

.education-footer {
    margin-top: -47px;
}

/* About */
.header-container-about {
    height: 85vh;
    width: 100vw;
    position: relative;
    align-items: center;
    justify-content: center;
    background: url("../img/diamondbg.jpg");
    background-position: 0% 25%;
    background-size: calc(1520px);
    background-attachment: fixed;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.header-container-about::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #c0c0c0;
    opacity: 0.4;
}

.about-mt {
    margin-top: 0px;
}

/* Image Slider */
.slider {
    /* background: #afa7a2; */
    height: auto;
    margin: auto;
    /* top:-25%; */
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slide-track {
    display: flex;
    width: calc(300px * 12);
    animation: scroll 30s linear infinite;
}

.slide-track:hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-300px * 6));
    }
}

.slide {
    height: 200px;
    width: 400px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    perspective: 100px;
}

.slide img {
    width: 200px;
    height: auto;
    object-fit: cover;
    transition: transform 1s;
    /* opacity: 0.85; */
}

.slide img:hover {
    opacity: 1;
    border-radius: 10px;
    transform: translateZ(10px);
}

.slider::before {
    left: 0;
    top: 0;
}

.slider::after {
    right: -2px;
    top: 0;
    transform: rotateZ(180deg);
}

.img-1 {
    height: 60px !important;
    width: auto !important;
}

.img-2 {
    height: 70px !important;
    width: auto !important;
}

.img-3 {
    height: 63px !important;
    width: auto !important;
}


/* here */

@media screen and (min-device-width: 1920px) {
    body section {
        max-width: 1520px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .header-container-home {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    /*.header-container-home::before {*/
    /*    content: "";*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    right: 0;*/
    /*    bottom: 0;*/
    /*    left: 0;*/
    /*    opacity: 0;*/
    /*}*/
    .header-text-home img {
        margin-top: 13.7%;
        width: 4.5%;
        z-index: -100;
        animation: diamond_anim 4s cubic-bezier(0.25, 1, 0.5, 1) infinite alternate;
    }

    .home_section_bg_1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .home-section-2-img {
        width: 1520px;
        height: 72vh;
    }

    .header-container-products {
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .header-container-education-1 {
        background-size: calc(1720px);
        background-attachment: fixed;
    }

    .header-container-about {
        background-size: 100%;
    }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Navbar */
    .icon-img {
    }

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
    body {
        overflow-x: hidden;
        letter-spacing: 0px;
    }

    .heading-text {
        font-family: "Bellefair", serif;
        font-size: 40px;
    }

    * {
        margin: 0;
        padding: 0;

    }

    .pt {
        padding-top: 50px;
    }

    .pt-sm {
        padding-top: 30px;
    }

    .pb {
        padding-bottom: 10px;
    }

    .ms {
        margin-left: 50px;
    }

    .logo_img {
        height: 50px;
        width: auto;
    }

    .email-svg {
        height: 30px;
        margin-top: 5px;
        margin-right: 5px;
    }

    .custom-container {
        margin: 0 25px;
    }

    .custom-padding {
        padding-top: 0px;
        padding-bottom: 20px;
        padding: 0 10px;
    }

    .cta span {
        font-size: 16px;
    }

    .header-container-home {
        height: 65vh;
        position: relative;
        background: url("../img/bg-30.jpg");
        background-position: 20% 0%;
        background-repeat: no-repeat;
        background-size: calc(1000px);
        overflow: hidden;
    }

    .header-container-home::before {
        content: "";
        height: 65vh;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        opacity: 0.6;
    }

    .home-logo-img {
        height: 150px;
        width: auto;
        left: -4%;
        top: 6.5%;
    }

    .parallax-text {
        /*width: 100%;*/
        font-size: 2.5rem;
        left: 5%;
        top: 16%;
    }

    .header-text-home img {
        margin-top: 24.8%;
        width: 8%;
        animation: diamond_anim 4s cubic-bezier(0.25, 1, 0.5, 1) infinite alternate;
    }

    @keyframes diamond_anim {
        0%,
        10% {
            transform: translate(100vw, 0) rotate(45deg);
        }

        90%,
        100% {
            transform: translate(-27vw, 0) rotate(-180deg);
        }
    }
    .home-heading {
        font-size: 18px;
    }

    .header-text-home-2 {
        font-size: 100px;
    }

    .home_section_bg_1 {
        background-color: #c0c0c0;
    }

    .home-section-2-img {
        display: none;
    }

    /* review */
    .card {
        padding: 10px;
    }

    .inspiration-text {
        font-size: 21px
    }

    /* Products */
    .header-container-products {
        height: 65vh;
        width: 100vw;
        position: relative;
        background-position: 45% 10%;
        background-size: calc(1220px);
        background-attachment: scroll;
    }

    .header-container-products::before {
        height: 65vh;
    }

    .header-products-text {
        top: 25%;
    }

    .product-section-3-img {
        height: 50px !important;
        width: auto;
        margin: 10px 20px;
    }

    /* gallery */
    .container-box {
        height: 60vh;
        /* width: 100vw; */
    }

    .slide {
        width: 200px;
    }

    .img-1 {
        height: 50px !important;
        width: auto !important;
    }

    .img-2 {
        height: 55px !important;
        width: auto !important;
    }

    .img-3 {
        height: 51px !important;
        width: auto !important;
    }

    /* education */
    .diamond_4c_img {
        width: 250px;
        height: auto;
    }

    .header-container-education-1 {
        height: 65vh;
        background-position: 60% 0%;
        background-size: calc(1000px);
        background-attachment: scroll;
        overflow: hidden;
    }

    .header-container-education-1::before {
        height: 65vh;
        opacity: 0.6;
    }

    .header-education-text {
        top: 13%;
    }

    .header-education-text-sub {
        font-size: 18px;
    }

    .row {
        --bs-gutter-x: 0;
    }

    /* About */
    .header-container-about {
        height: 65vh;
        background-position: 59% 0%;
        background-size: calc(900px);
        background-attachment: scroll;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    .header-container-about::before {
        height: 65vh;
        opacity: 0.5;
    }

    .header-about-text {
        top: 29%;
        left: 0%;
    }

    .founder-img {
        height: 120px !important;
        width: auto;
        border-radius: 50% !important;
    }

    .about-mt {
        margin-top: -20px;
    }

    #founder {
        margin-left: 0px !important;
        text-align: center;
    }

    .contact-form {
        padding: 0 7px !important;
    }

    .input {
        width: 250px !important;
    }

    .text-area {
        height: 100px !important;
        width: 250px !important;
    }

}

.input {
    width: 500px;
}

.text-area {
    height: 100px;
    width: 500px;
}

.contact-form {
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
}

.contact-form label {
    color: black;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    margin-bottom: 10px;
}

.contact-form input, .contact-form textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
    border: .5px solid black;
    /* box-shadow: .5px .5px 5px black; */
}

.contact-form input:focus, .contact-form textarea:focus {
    outline: none;
    box-shadow: 0 0 5px #494949;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

.contact-form button[type="submit"] {
    background-color: #494949;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.contact-form button[type="submit"]:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}


.product-section-3 {
    width: 100vw;
    height: 45vh;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.product-section-3-img {
    height: 70px;
    width: auto;
}


.education-section-3 {
    width: 100vw;
    height: auto;
    background-color: #c0c0c0;
}

.founder-img {
    width: auto;
    height: 180px;
    border-radius: 50%;
}

.Toastify__toast-container {
    text-align: start;
}